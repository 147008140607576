/* eslint-disable react/prop-types */

import React from "react"
import TrackVisibility from "react-on-screen"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, StaticQuery } from "gatsby"
import { useWindowHeight } from "@react-hook/window-size"

import Animation from "@src/components/animation"
import BackgroundImage from "@src/components/background-image/static"
import SVG from "@src/components/svg/component"
import VideoModal from "@src/components/video-modal"

import { ReactComponent as RebrandBadge } from "@uploads/adventurous-life/01--rebrand--badge.svg"

import windDancing from "../../../../images/01--wind-dancing.gif"

import styles from "./styles.module.scss"

const Started = ({
  bornForAdventure_copy,
  community,
  revealed,
  started_heading,
  started_image_text
}) => {
  const imageDirectory = "../../../../../static/uploads/adventurous-life/"
  const delayAnimation = useWindowHeight() >= 1200

  return (
    <div className={styles.section}>
      <BackgroundImage
        className={styles.container}
        image="adventurous-life/01--medical-debt--bg@2x.jpg"
      >
        <div className={styles.top}>
          <div>
            <TrackVisibility once>
              {({ isVisible }) => (
                <h2
                  className={classNames({
                    [[`is-hidden`]]: !revealed || !isVisible,
                    [[`animate__animated fadeInUp`]]: revealed && isVisible,
                    [[`animation-delay-10`]]: delayAnimation
                  })}
                >
                  {started_heading}
                </h2>
              )}
            </TrackVisibility>
            <figure>
              <TrackVisibility once>
                {({ isVisible }) => (
                  <div
                    className={classNames(`reveal-from-left`, {
                      [[`is-hidden`]]: !revealed || !isVisible,
                      [[`is-showing`]]: revealed && isVisible,
                      [[`transition-delay-12`]]: delayAnimation
                    })}
                  >
                    {isVisible && (
                      <StaticImage
                        alt="Super bowl was super sized"
                        placeholder="none"
                        src={`${imageDirectory}01--running--super-bowl@2x.jpg`}
                        width={653}
                        height={220}
                        layout="fixed"
                      />
                    )}
                  </div>
                )}
              </TrackVisibility>
              <figcaption>{started_image_text}</figcaption>
            </figure>
          </div>
          <div className={styles.born_for_container}>
            <StaticQuery
              query={graphql`
                query {
                  file(relativePath: { eq: "adventurous-life/texture-grid-dark-80@2x.png" }) {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
              `}
              render={(data) => (
                <TrackVisibility partialVisibility once>
                  {({ isVisible }) => (
                    <div
                      className={classNames(styles.born_for, {
                        [[`is-hidden`]]: !revealed || !isVisible,
                        [[`animate__animated fadeInUp`]]: revealed && isVisible,
                        [[`animation-delay-9`]]: delayAnimation
                      })}
                      style={{ backgroundImage: `url(${data.file.childImageSharp.fluid.src})` }}
                    >
                      <div>
                        <RebrandBadge />

                        <VideoModal
                          video={{
                            height: "145px",
                            youtube_id: "z_kzIV8M44I",
                            thumb: "adventurous-life/01a-your-adventure-begins-here.mp4"
                          }}
                        />

                        <p>{bornForAdventure_copy}</p>
                      </div>
                    </div>
                  )}
                </TrackVisibility>
              )}
            />
          </div>
        </div>
        <div className={styles.bottom}>
          <Animation fade="left" contentClassName={styles.community} partialVisibility>
            <div className={styles.arrow}>
              <SVG name="arrow-down-alt" />
            </div>
            <div>{community}</div>
          </Animation>

          <div className={styles.video_and_image}>
            <Animation revealFrom="bottom" revealColor="tan">
              <VideoModal
                video={{
                  height: "180px",
                  youtube_id: "93a9WhPfBWY",
                  thumb: "adventurous-life/01--crossroads-church-rip-medical-debt-campaign-2020.mp4"
                }}
              />
            </Animation>

            <Animation contentClassName={styles.gif} revealFrom="bottom">
              <img src={windDancing} alt="Wind Dancing" />
            </Animation>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

Started.propTypes = {}

Started.defaultProps = {}

export default Started
